import React from "react"
import { IconLock, IconKey } from "@tabler/icons"

const Security = () => (
  <section className="text-gray-300">
    <div className="max-w-5xl mx-4 md:mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 text-white py-20">
      <div className="flex gap-6 items-start">
        <div className="border border-gray-100 shadow-button bg-gray-900 rounded-lg p-1">
          <IconLock size={32} stroke={1} />
        </div>
        <div className="flex flex-col gap-3">
          <h3 className="md:text-2xl font-semibold">Safe &amp; Secure</h3>
          <p>
            SSL everywhere, database encryption at rest, 100% cloud-based
            architecture secured behind a VPC, and independent security audits.
          </p>
        </div>
      </div>
      <div className="flex gap-6 items-start">
        <div className="border border-gray-100 shadow-button bg-gray-900 rounded-lg p-1">
          <IconKey size={32} stroke={1} />
        </div>
        <div className="flex flex-col gap-3">
          <h3 className="md:text-2xl font-semibold">SAML single sign-on</h3>
          <p>
            Manage employee access at scale with secure single sign-on. Included
            with our Enterprise Plan.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default Security
